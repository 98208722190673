import { BUILDER_MENU_CONFIG } from './builder-menu-config';
import {
  loadMenuPartnerIdSuccess,
  loadMenuSettingsSuccess,
  reset,
  toggleHomepageSubEntries,
  toggleMenuEntryAfterNavigate,
  updateDynamicKeysAfterNavigate,
} from './builder-menu.actions';
import { Params } from '@angular/router';
import { MenuTab } from '@domains/multiple-side-menu/models/multiple-side-menu';
import { HOMEPAGE_ROUTING } from '@features/partners/builder/pages/homepage/routes';
import { PAGES_ROUTING } from '@features/partners/builder/pages/routes';
import { MenuSettings } from '@models/menu-settings/menu-settings';
import { createReducer, on } from '@ngrx/store';

export type BuilderMenuState = {
  tabs: Array<MenuTab>;
};

const InitialMenuState: BuilderMenuState = {
  tabs: BUILDER_MENU_CONFIG,
};

export const menuFeatureKey = 'menuState';

export const menuReducer = createReducer(
  InitialMenuState,
  on(loadMenuSettingsSuccess, onLoadMenuSettingSuccess),
  on(loadMenuPartnerIdSuccess, onLoadMenuPartnerIdSuccess),
  on(toggleHomepageSubEntries, onToggleHomepageSubEntries),
  on(toggleMenuEntryAfterNavigate, onToggleMenuEntryAfterNavigate),
  on(updateDynamicKeysAfterNavigate, onUpdateDynamicKeysAfterNavigate),
  on(reset, onReset)
);

function onToggleHomepageSubEntries(
  state: BuilderMenuState,
  { isDisabled }: { isDisabled: boolean }
): BuilderMenuState {
  const tabs = state.tabs.map(({ label, menu }) => {
    const updatedMenu = menu.map((item) => {
      if (item.initialRoute === undefined) {
        return item;
      }

      if (item.initialRoute.endsWith(PAGES_ROUTING.Homepage) === false) {
        return item;
      }

      const children = item.children.map((child) =>
        child.initialRoute?.endsWith(HOMEPAGE_ROUTING.infos) ? child : { ...child, isDisabled }
      );

      return { ...item, children };
    });

    return { label, menu: updatedMenu };
  });
  return { ...state, tabs };
}

function onLoadMenuSettingSuccess(state: BuilderMenuState, { settings }: { settings: MenuSettings }): BuilderMenuState {
  const disabledItems = settings.filter(({ activated }) => !activated).map(({ key }) => key);

  const updatedTabs = state.tabs.map(({ label, menu }) => {
    const updatedMenu = menu.map((item) => {
      const updatedChildren = item.children.map((child) =>
        child.disablingKey === undefined ? child : { ...child, isDisabled: disabledItems.includes(child.disablingKey) }
      );

      if (item.disablingKey === undefined) return { ...item, children: updatedChildren };

      return { ...item, children: updatedChildren, isDisabled: disabledItems.includes(item.disablingKey) };
    });

    return { label, menu: updatedMenu };
  });

  return { ...state, tabs: updatedTabs };
}

function onLoadMenuPartnerIdSuccess(state: BuilderMenuState, { partnerId }: { partnerId: string }): BuilderMenuState {
  const partnersRoute = '/partners';

  const updatedTabs = state.tabs.map(({ label, menu }) => {
    const updatedMenu = menu.map((item) => {
      const updatedChildren = item.children.map((child) => ({
        ...child,
        route: `${partnersRoute}/${partnerId}/${child.initialRoute}`,
      }));

      if (item.initialRoute === undefined) return { ...item, children: updatedChildren };

      return { ...item, children: updatedChildren, route: `${partnersRoute}/${partnerId}/${item.initialRoute}` };
    });
    return { label, menu: updatedMenu };
  });

  return { ...state, tabs: updatedTabs };
}

function onToggleMenuEntryAfterNavigate(state: BuilderMenuState, { url }: { url: string }): BuilderMenuState {
  const updatedTabs = state.tabs.map(({ label, menu }) => {
    const updatedMenu = menu.map((item) => {
      const updatedChildren = item.children.map((child) => {
        if (child.displayOptions === undefined) return child;

        const isDisplayed = child.displayOptions.regexp.test(url);
        return { ...child, displayOptions: { ...child.displayOptions, isDisplayed } };
      });

      return { ...item, children: updatedChildren };
    });

    return { label, menu: updatedMenu };
  });

  return { ...state, tabs: updatedTabs };
}

function onUpdateDynamicKeysAfterNavigate(state: BuilderMenuState, { params }: { params: Params }): BuilderMenuState {
  const updatedTabs = state.tabs.map(({ label, menu }) => {
    const updatedMenu = menu.map((item) => {
      const updatedChildren = item.children.map((child) => {
        if (child.displayOptions === undefined || child.initialRoute === undefined || child.dynamicKey === undefined)
          return child;

        const dynamicKey = params[child.dynamicKey];

        const updatedRoute = dynamicKey
          ? child.initialRoute?.replace('{{dynamicKey}}', dynamicKey)
          : child.initialRoute;

        const route = `/partners/${params['partnerId']}/${updatedRoute}`;

        return { ...child, route };
      });

      return { ...item, children: updatedChildren };
    });

    return { label, menu: updatedMenu };
  });

  return { ...state, tabs: updatedTabs };
}

function onReset(state: BuilderMenuState): BuilderMenuState {
  return { ...state, tabs: InitialMenuState.tabs };
}
